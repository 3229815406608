<template>
  <div
    class="modern-color-theme"
    :class="{
      'w-full': props.wide,
      'w-min': !props.wide,
      'relative': props.disabled === 'loading'
    }"
    v-bind="containerProps"
  >
    <button
      v-bind="$attrs"
      type="button"
      class="font-poppins block w-full flex items-center justify-around rounded-md gap-4 whitespace-nowrap h-min font-semibold overflow-ellipsis overflow-hidden focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
      :class="computedClass"
      :tabindex="props.tabindex"
      :disabled="!!props.disabled"
      :title="props.title"
    >
      <slot name="prefix" />
      <slot />
      <slot name="suffix" />
    </button>
    <div v-if="props.disabled === 'loading'" class="absolute inset-0 flex items-center justify-center pointer-events-none">
      <VSpinner :size="props.size" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Color, Size, Variant } from '../../utils/types.js'
import VSpinner from '../labels/VSpinner.vue';

defineOptions({
  name: 'VButton',
  inheritAttrs: false
})

type ButtonColor = Extract<Color, 'primary' | 'neutral' | 'red'>

const props = withDefaults(
  defineProps<{
    variant?: Variant
    color?: ButtonColor
    wide?: boolean
    disabled?: boolean | 'loading'
    size?: Size
    tabindex?: number | string
    title?: string
    icon?: boolean
    round?: boolean
    containerProps?: Record<string, unknown>
  }>(),
  {
    variant: 'contained',
    color: 'primary',
    size: 'sm',
    wide: false,
    tabindex: undefined,
    title: undefined,
    icon: false,
    round: false,
    disabled: false,
    containerProps: Object.create(null)
  }
)

const COLOR_SCHEMES: Record<`${Variant}/${ButtonColor}`, string> = {
  'contained/primary': 'border-primary-500 bg-primary-500 text-neutral-100 hover:bg-primary-450 hover:border-primary-450',
  'outlined/primary': 'border-primary-500 bg-neutral-100 text-primary-500 hover:text-primary-450 hover:border-primary-450',
  'text/primary': 'text-primary-500',
  'contained/neutral': '', // TODO
  'outlined/neutral': 'border-neutral-300 bg-neutral-100 text-neutral-900 hover:border-neutral-250',
  'text/neutral': 'text-neutral-900',
  'contained/red': 'border-red-500 bg-red-500 text-neutral-100 hover:bg-red-450 hover:border-red-450', // TODO
  'outlined/red': 'border-red-500 bg-neutral-100 text-red-500 hover:text-red-400 hover:border-red-400 focus-visible:outline-red-500',
  'text/red': '' // TODO
}

const computedClass = computed(() => {
  const classes = []

  if (props.variant !== 'text') {
    classes.push('border')

    if (props.icon) {
      classes.push('p-[calc(0.25rem+2px)]')
    } else {
      classes.push('px-3 py-2')
    }
  }

  if (props.round) {
    classes.push('!rounded-full')
  }

  if (props.size) {
    classes.push(`text-${props.size}`)
  }

  classes.push(COLOR_SCHEMES[`${props.variant}/${props.color}`])

  return classes
})
</script>
